.card_status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 10px;
}

.card_image {
  margin: 0px;
}

.card_text p {
  margin: 0px;
}

/* .active {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important; 
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
} */

@media screen and (max-width: 765px) {
  .card_status {
    margin-top: 15px;
  }
}