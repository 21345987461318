.text-justify {
    text-align: justify;
    line-height: 26px;
}

@media screen and (max-width: 768px) {
    .buttons {
        display: flex;
        gap: 15px;
    }

    .buttons .btn {
        width: 100%;
    }
}