
/* 
===================
    User Profile
===================
*/
.user-profile .widget-content-area {
  border: none;
  display: flex;
  flex-direction: row;
  justify-items: auto;
  justify-content: space-evenly;
}

@media screen and (max-width: 426px) {
  .user-profile .widget-content-area {
    flex-direction: column;
  }
}

.user-profile .widget-content-area h3 {
  font-size: 21px;
  color: #0e1726;
  margin: 6px 0px 0 0;
}
.user-profile .widget-content-area .user-info {
  margin-top: 40px;
}
.user-profile .widget-content-area .user-info img {
  border-radius: 9px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.user-profile .widget-content-area .user-info p {
  font-size: 20px;
  font-weight: 600;
  margin-top: 22px;
  color: #009688;
}
.user-profile .widget-content-area .user-info-list > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.user-profile .widget-content-area .user-info-list ul.contacts-block {
  border: none;
  max-width: 217px;
  margin: 30px 0 0 0;
}
.user-profile .widget-content-area .user-info-list ul.contacts-block li {
  margin-bottom: 13px;
  font-weight: 600;
  font-size: 13px;
}
.user-profile .widget-content-area .user-info-list ul.contacts-block li a:not(.btn) {
  font-weight: 600;
  font-size: 15px;
  color: #009688;
}
.user-profile .widget-content-area .user-info-list ul.contacts-block a:not(.btn) svg {
  width: 21px;
  color: #888ea8;
  vertical-align: middle;
}
