/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.flatpickr-input[readonly] {
  color: #888ea8;
}

.flatpickr-calendar {
  width: 336.875px;
  padding: 15px;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  background: #fff;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 900;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #ebedf2;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #ebedf2;
}
.flatpickr-calendar:before {
  border-width: 9px;
}
.flatpickr-calendar:after {
  border-width: 0px;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  top: 8%;
  padding: 5px 13px;
  background: #fff;
  border-radius: 4px;
  height: 40px;
}
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  fill: #888ea8;
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #4361ee;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #bfc9d4;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #bfc9d4;
}

.flatpickr-day.today {
  border-color: #4361ee;
  color: #4361ee;
  font-weight: 700;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  height: auto;
  border: 1px solid #bfc9d4;
  color: #3b3f5c;
  font-size: 15px;
  padding: 12px 16px;
  letter-spacing: 1px;
  font-weight: 700;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #fff;
}
.flatpickr-current-month input.cur-year {
  height: auto;
  border: 1px solid #bfc9d4;
  border-left: none;
  color: #3b3f5c;
  font-size: 15px;
  padding: 13px 12px;
  letter-spacing: 1px;
  font-weight: 700;
}

.flatpickr-months .flatpickr-month {
  height: 76px;
}

.flatpickr-day.flatpickr-disabled {
  cursor: not-allowed;
  color: #e0e6ed;
}
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #e0e6ed;
}

span.flatpickr-weekday {
  color: #888ea8;
}

.flatpickr-day {
  color: #3b3f5c;
  font-weight: 600;
}
.flatpickr-day.flatpickr-disabled {
  color: #bfc9d4;
}
.flatpickr-day.flatpickr-disabled:hover {
  color: #bfc9d4;
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: #bfc9d4;
}
.flatpickr-day.notAllowed {
  color: #bfc9d4;
}
.flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #bfc9d4;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: #e0e6ed;
  border-color: #e0e6ed;
  -webkit-box-shadow: -5px 0 0 #e0e6ed, 5px 0 0 #e0e6ed;
  box-shadow: -5px 0 0 #e0e6ed, 5px 0 0 #e0e6ed;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #4361ee;
  color: #fff;
  border-color: #4361ee;
  font-weight: 700;
}

.flatpickr-time input {
  color: #3b3f5c;
}
.flatpickr-time input:hover {
  background: #e0e6ed;
}
.flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #e0e6ed;
}
.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  color: #3b3f5c;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #4361ee;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #4361ee;
}

@supports (-webkit-overflow-scrolling: touch) {
  .form-control {
    height: auto;
  }
}
