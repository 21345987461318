/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.nav.nav-tabs {
  border: none;
}
.nav.nav-tabs li.nav-item button.nav-link {
  border: none;
  font-weight: 500;
  padding: 8px 14px;
  letter-spacing: 1px;
  color: #506690;
  background: transparent;
}
.nav.nav-tabs li.nav-item button.nav-link svg {
  vertical-align: text-bottom;
  stroke-width: 1.6;
  width: 20px;
  height: 20px;
}
.nav.nav-tabs li.nav-item button.nav-link.active {
  border-bottom: 1.6px solid #4361ee;
  color: #4361ee;
  font-weight: 600;
}
.nav.nav-tabs li.nav-item button.nav-link.active svg {
  color: #4361ee;
}
.nav.nav-tabs li.nav-item button.nav-link:disabled {
  opacity: 0.5;
}
.nav.nav-pills {
  border: none;
  padding: 8px;
  background: #ebedf2;
  border-radius: 8px;
}
.nav.nav-pills li.nav-item button.nav-link {
  border: none;
  font-weight: 500;
  padding: 8px 14px;
  letter-spacing: 1px;
  color: #4361ee;
}
.nav.nav-pills li.nav-item button.nav-link svg {
  vertical-align: text-bottom;
  stroke-width: 1.6;
  color: #4361ee;
  width: 20px;
  height: 20px;
}
.nav.nav-pills li.nav-item button.nav-link.active {
  border-bottom: none;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  font-weight: 600;
}
.nav.nav-pills li.nav-item button.nav-link:disabled {
  opacity: 0.5;
}
.nav.nav-pills[aria-orientation=vertical] button.nav-link {
  border: none;
  font-weight: 500;
  padding: 8px 14px;
  letter-spacing: 1px;
  color: #4361ee;
}
.nav.nav-pills[aria-orientation=vertical] button.nav-link svg {
  vertical-align: bottom;
  stroke-width: 1.6;
  color: #4361ee;
  width: 20px;
  height: 20px;
}
.nav.nav-pills[aria-orientation=vertical] button.nav-link.active {
  border-bottom: none;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.nav.nav-pills[aria-orientation=vertical] button.nav-link:disabled {
  opacity: 0.5;
}
