/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
body:before {
  display: none;
}

.card {
  background-color: transparent;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.auth-container {
  min-height: 100vh;
}
.auth-container .container {
  max-width: 1440px;
}

.seperator {
  position: relative;
}
.seperator .seperator-text {
  position: absolute;
  top: -10px;
  display: block;
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}
.seperator .seperator-text span {
  background-color: #fafafa;
  padding: 0 12px;
  display: inline-block;
}

.opt-input {
  padding: 12px 14px;
  text-align: center;
}

.btn-social-login img {
  width: 25px;
  height: 25px;
}

@media (max-width: 575px) {
  body {
    height: 100vh;
  }
  .card {
    border: none;
    background-color: transparent;
  }

  .auth-container {
    height: auto;
  }
  .auth-container .card {
    background-color: transparent;
    box-shadow: none;
  }
  .auth-container .card .card-body {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
