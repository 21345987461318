/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.layout-spacing {
  padding-bottom: 25px;
}

.widget {
  position: relative;
  padding: 20px;
  border-radius: 6px;
  border: none;
  background: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}
.widget .widget-heading {
  margin-bottom: 15px;
}
.widget h5 {
  letter-spacing: 0px;
  font-size: 19px;
  display: block;
  color: #0e1726;
  font-weight: 600;
  margin-bottom: 0;
}
.apexcharts-legend-text {
  color: #3b3f5c !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  background: #191e3a !important;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  background: #191e3a !important;
  border-bottom: 1px solid #191e3a;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ==================
        Total Sales
    ==================
*/
.widget-two {
  position: relative;
  background: #fff;
  padding: 0;
  border-radius: 6px;
  height: 100%;
  box-shadow: none;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}
.widget-two .widget-content {
  font-size: 17px;
}
.widget-two .w-chart {
  position: absolute;
  bottom: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.widget-two .w-numeric-value {
  display: flex;
  color: #fff;
  font-weight: 500;
  padding: 20px;
  justify-content: space-between;
}
.widget-two .w-numeric-value .w-icon {
  display: inline-block;
  background: #fcf5e9;
  padding: 13px 12px;
  border-radius: 50%;
  display: inline-flex;
  align-self: center;
  height: 45px;
  width: 45px;
}
.widget-two .w-numeric-value svg {
  display: block;
  color: #e2a03f;
  width: 20px;
  height: 20px;
}
.widget-two .w-numeric-value .w-value {
  margin-bottom: -9px;
  letter-spacing: 0px;
  font-size: 19px;
  display: block;
  color: #0e1726;
  font-weight: 600;
}
.widget-two .w-numeric-value .w-numeric-title {
  font-size: 13px;
  color: #888ea8;
  font-weight: 600;
}

@media (max-width: 575px) {
  /*
      ==================
          Total Sales
      ==================
  */
  .widget-two .w-chart {
    position: inherit;
  }
}
/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ==================
        Widget
    ==================
*/
.widget-one {
  position: relative;
  padding: 0;
  border-radius: 6px;
  border: none;
  background-color: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}
.widget-one .widget-content {
  font-size: 17px;
}
.widget-one .w-numeric-value {
  position: absolute;
  display: flex;
  color: #fff;
  font-weight: 500;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
}
.widget-one .w-numeric-value .w-icon {
  display: inline-block;
  background: #ddf5f0;
  padding: 13px 12px;
  border-radius: 12px;
  display: inline-flex;
  align-self: center;
  height: 45px;
  width: 45px;
  margin-right: 14px;
}
.widget-one .w-numeric-value svg {
  display: block;
  color: #009688;
  width: 20px;
  height: 20px;
  fill: rgba(26, 188, 156, 0.49);
}
.widget-one .w-numeric-value .w-value {
  font-size: 26px;
  display: block;
  color: #515365;
  font-weight: 600;
  margin-bottom: -9px;
  text-align: right;
}
.widget-one .w-numeric-value .w-numeric-title {
  font-size: 13px;
  color: #515365;
  letter-spacing: 1px;
  font-weight: 600;
}
.widget-one .apexcharts-canvas svg {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ====================
        Order Summary
    ====================
*/
.widget-three {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}
.widget-three .widget-heading {
  margin-bottom: 54px;
  display: flex;
  justify-content: space-between;
}
.widget-three .widget-heading h5 {
  font-size: 19px;
  display: block;
  color: #0e1726;
  font-weight: 600;
  margin-bottom: 0;
}
.widget-three .widget-heading .task-action .dropdown-toggle svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}
.widget-three .widget-heading .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}
.widget-three .widget-content {
  font-size: 17px;
}
.widget-three .widget-content .summary-list {
  display: flex;
}
.widget-three .widget-content .summary-list:not(:last-child) {
  margin-bottom: 30px;
}
.widget-three .widget-content .w-icon {
  display: inline-block;
  padding: 8px 8px;
  border-radius: 50%;
  display: inline-flex;
  align-self: center;
  height: 34px;
  width: 34px;
  margin-right: 12px;
}
.widget-three .widget-content .w-icon svg {
  display: block;
  width: 17px;
  height: 17px;
}
.widget-three .widget-content .summary-list:nth-child(1) .w-icon {
  background: #f2eafa;
}
.widget-three .widget-content .summary-list:nth-child(2) .w-icon {
  background: #ddf5f0;
}
.widget-three .widget-content .summary-list:nth-child(3) .w-icon {
  background: #fcf5e9;
}
.widget-three .widget-content .summary-list:nth-child(1) .w-icon svg {
  color: #805dca;
}
.widget-three .widget-content .summary-list:nth-child(2) .w-icon svg {
  color: #009688;
}
.widget-three .widget-content .summary-list:nth-child(3) .w-icon svg {
  color: #e2a03f;
}
.widget-three .widget-content .w-summary-details {
  width: 100%;
  align-self: center;
}
.widget-three .widget-content .w-summary-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
}
.widget-three .widget-content .w-summary-info h6 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
  color: #888ea8;
}
.widget-three .widget-content .w-summary-info p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  color: #888ea8;
}
.widget-three .widget-content .w-summary-stats .progress {
  margin-bottom: 0;
  height: 6px;
  border-radius: 20px;
  box-shadow: none;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ==================
        Revenue
    ==================
*/
.widget-chart-one .widget-heading {
  display: flex;
  justify-content: space-between;
}
.widget-chart-one #revenueMonthly {
  overflow: hidden;
}
.widget-chart-one .widget-content .apexcharts-canvas {
  transition: 0.5s;
}
.widget-chart-one .widget-content .apexcharts-canvas svg {
  transition: 0.5s;
}
.widget-chart-one .apexcharts-legend-marker {
  left: -5px !important;
}
.widget-chart-one .apexcharts-yaxis-title, .widget-chart-one .apexcharts-xaxis-title {
  font-weight: 600;
  fill: #888e88;
}
.widget-chart-one .widget-heading .task-action svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}
.widget-chart-one .widget-heading .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    =======================
        Sold By cateory
    =======================
*/
.widget-chart-two {
  padding: 0;
}

.widget.widget-chart-two .widget-heading {
  padding: 20px 20px 0 20px;
}

.widget-chart-two .widget-heading .w-icon {
  position: absolute;
  right: 20px;
  top: 15px;
}
.widget-chart-two .widget-heading .w-icon a {
  padding: 6px;
  border-radius: 10px;
  padding: 6px;
  background: #3b3f5c !important;
  border: none;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.widget-chart-two .widget-heading .w-icon a svg {
  color: #fff;
}

.widget.widget-chart-two .widget-content {
  padding: 0 0 20px 0;
}

.widget-chart-two .apexcharts-canvas {
  margin: 0 auto;
}
.widget-chart-two .apexcharts-legend-marker {
  left: -5px !important;
}

[id*=apexcharts-donut-slice-] {
  filter: none;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ==================
        Transaction
    ==================
*/
.widget-table-one .widget-heading {
  display: flex;
  margin-bottom: 31px;
  justify-content: space-between;
}
.widget-table-one .widget-heading .task-action .dropdown-toggle svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}
.widget-table-one .widget-heading .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}
.widget-table-one .transactions-list {
  border-radius: 6px;
}
.widget-table-one .transactions-list:not(:last-child) {
  margin-bottom: 22.2px;
}
.widget-table-one .transactions-list .t-item {
  display: flex;
  justify-content: space-between;
}
.widget-table-one .transactions-list .t-item .t-company-name {
  display: flex;
}
.widget-table-one .transactions-list .t-item .t-icon {
  margin-right: 12px;
}
.widget-table-one .transactions-list .t-item .t-icon .avatar {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  width: auto;
  height: auto;
}
.widget-table-one .transactions-list .t-item .t-icon .avatar .avatar-title {
  background-color: #fbeced;
  color: #e7515a;
  border-radius: 12px;
  position: relative;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
}
.widget-table-one .transactions-list.t-info .t-item .t-icon .avatar .avatar-title {
  color: #2196f3;
  background: #e6f4ff;
}
.widget-table-one .transactions-list.t-secondary .t-item .t-icon .icon {
  color: #805dca;
  background-color: #f2eafa;
}
.widget-table-one .transactions-list.t-secondary .t-item .t-icon .icon svg {
  color: #805dca;
}
.widget-table-one .transactions-list .t-item .t-icon .icon {
  position: relative;
  background-color: #fcf5e9;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
}
.widget-table-one .transactions-list .t-item .t-icon .icon svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  color: #e2a03f;
  stroke-width: 2;
}
.widget-table-one .transactions-list .t-item .t-name {
  align-self: center;
}
.widget-table-one .transactions-list .t-item .t-name h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  transition: all 0.5s ease;
  color: #3b3f5c;
}
.widget-table-one .transactions-list:hover .t-item .t-name h4 {
  color: #2196f3;
}
.widget-table-one .transactions-list .t-item .t-name .meta-date {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  color: #888ea8;
}
.widget-table-one .transactions-list .t-item .t-rate {
  align-self: center;
}
.widget-table-one .transactions-list .t-item .t-rate p {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
}
.widget-table-one .transactions-list .t-item .t-rate svg {
  width: 14px;
  height: 14px;
  vertical-align: baseline;
}
.widget-table-one .transactions-list .t-item .t-rate.rate-inc p {
  color: #009688;
}
.widget-table-one .transactions-list .t-item .t-rate.rate-dec p {
  color: #e7515a;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ========================
        Recent Activities
    ========================
*/
.widget-activity-four {
  padding-right: 0;
  padding-left: 0;
}
.widget-activity-four .widget-heading {
  margin-bottom: 28px;
  padding: 0 20px;
}
.widget-activity-four .widget-heading .w-icon {
  position: absolute;
  right: 20px;
  top: 15px;
}
.widget-activity-four .widget-heading .w-icon a {
  padding: 6px;
  border-radius: 10px;
  padding: 6px;
  background: #3b3f5c !important;
  border: none;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.widget-activity-four .widget-heading .w-icon a svg {
  color: #fff;
}
.widget-activity-four .mt-container-ra {
  position: relative;
  height: 325px;
  overflow: auto;
  padding-right: 12px;
}
.widget-activity-four .widget-content {
  padding: 0 8px 0 20px;
}
.widget-activity-four .timeline-line .item-timeline {
  display: flex;
  width: 100%;
  padding: 8px 0;
  transition: 0.5s;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
}
.widget-activity-four .timeline-line .item-timeline .t-dot {
  position: relative;
}
.widget-activity-four .timeline-line .item-timeline .t-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  top: 5px;
  left: 5px;
  transform: translateX(-50%);
  border-color: #e0e6ed;
  background: #bfc9d4;
  z-index: 1;
}
.widget-activity-four .timeline-line .item-timeline .t-dot:after {
  position: absolute;
  border-color: inherit;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  left: 5px;
  transform: translateX(-50%);
  border-color: #e0e6ed;
  width: 0;
  height: auto;
  top: 12px;
  bottom: -19px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}
.widget-activity-four .timeline-line .item-timeline.timeline-primary .t-dot:before {
  background: #4361ee;
}
.widget-activity-four .timeline-line .item-timeline.timeline-success .t-dot:before {
  background-color: #009688;
}
.widget-activity-four .timeline-line .item-timeline.timeline-danger .t-dot:before {
  background-color: #e7515a;
}
.widget-activity-four .timeline-line .item-timeline.timeline-dark .t-dot:before {
  background-color: #607d8b;
}
.widget-activity-four .timeline-line .item-timeline.timeline-secondary .t-dot:before {
  background: #805dca;
}
.widget-activity-four .timeline-line .item-timeline.timeline-warning .t-dot:before {
  background-color: #e2a03f;
}
.widget-activity-four .timeline-line .item-timeline:last-child .t-dot:after {
  display: none;
}
.widget-activity-four .timeline-line .item-timeline .t-meta-time {
  margin: 0;
  min-width: 100px;
  max-width: 100px;
  font-size: 12px;
  font-weight: 700;
  color: #888ea8;
  align-self: center;
}
.widget-activity-four .timeline-line .item-timeline .t-text {
  align-self: center;
  margin-left: 14px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  transition: 0.5s;
}
.widget-activity-four .timeline-line .item-timeline .t-text p {
  margin: 0;
  font-size: 13px;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 0;
  color: #515365;
}
.widget-activity-four .timeline-line .item-timeline .t-text p a {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: #009688;
}
.widget-activity-four .timeline-line .item-timeline .t-text span.badge {
  position: absolute;
  right: -1px;
  padding: 2px 4px;
  font-size: 10px;
  letter-spacing: 1px;
  opacity: 0;
  font-weight: 600;
  transform: none;
  top: 6px;
}
.widget-activity-four .timeline-line .item-timeline.timeline-primary .t-text span.badge {
  color: #fff;
  border: 1px solid #4361ee;
  background-color: #4361ee;
}
.widget-activity-four .timeline-line .item-timeline.timeline-secondary .t-text span.badge {
  color: #fff;
  border: 1px solid #805dca;
  background-color: #805dca;
}
.widget-activity-four .timeline-line .item-timeline.timeline-danger .t-text span.badge {
  color: #fff;
  border: 1px solid #e7515a;
  background-color: #e7515a;
}
.widget-activity-four .timeline-line .item-timeline.timeline-warning .t-text span.badge {
  color: #fff;
  border: 1px solid #e2a03f;
  background-color: #e2a03f;
}
.widget-activity-four .timeline-line .item-timeline.timeline-success .t-text span.badge {
  color: #fff;
  border: 1px solid #009688;
  background-color: #009688;
}
.widget-activity-four .timeline-line .item-timeline.timeline-dark .t-text span.badge {
  color: #fff;
  border: 1px solid #3b3f5c;
  background-color: #3b3f5c;
}
.widget-activity-four .timeline-line .item-timeline:hover .t-text span.badge {
  opacity: 1;
}
.widget-activity-four .timeline-line .item-timeline .t-text p.t-time {
  text-align: right;
  color: #888ea8;
  font-size: 10px;
}
.widget-activity-four .timeline-line .item-timeline .t-time {
  margin: 0;
  min-width: 80px;
  max-width: 80px;
  font-size: 13px;
  font-weight: 600;
  color: #eaeaec;
  letter-spacing: 1px;
}
.widget-activity-four .tm-action-btn {
  text-align: center;
  padding-top: 19px;
}
.widget-activity-four .tm-action-btn button {
  background: transparent;
  box-shadow: none;
  padding: 0;
  color: #060818;
  font-weight: 800;
  letter-spacing: 0;
  border: none;
  font-size: 14px;
}
.widget-activity-four .tm-action-btn button:hover {
  transform: translateY(0);
}
.widget-activity-four .tm-action-btn button span {
  margin-right: 6px;
  display: inline-block;
  transition: 0.5s;
}
.widget-activity-four .tm-action-btn button:hover span {
  transform: translateX(-6px);
}
.widget-activity-four .tm-action-btn svg {
  width: 17px;
  height: 17px;
  vertical-align: sub;
  color: #888ea8;
  stroke-width: 2.5px;
  transition: 0.5s;
}
.widget-activity-four .tm-action-btn button:hover svg {
  transform: translateX(6px);
}

@media (max-width: 1199px) {
  .widget-activity-four .mt-container-ra {
    height: 184px;
  }
}
@media (max-width: 767px) {
  .widget-activity-four .mt-container-ra {
    height: 325px;
  }
}
/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    =====================
        Account Info
    =====================
*/
.widget-account-invoice-one .invoice-box .acc-total-info {
  padding: 0 0;
  margin-bottom: 60px;
  padding-bottom: 18px;
  border-bottom: 1px dashed #bfc9d4;
}
.widget-account-invoice-one .invoice-box h5 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: #4361ee;
}
.widget-account-invoice-one .invoice-box .acc-amount {
  text-align: center;
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0;
  color: #009688;
}
.widget-account-invoice-one .invoice-box .inv-detail {
  margin-bottom: 55px;
  padding-bottom: 18px;
  border-bottom: 1px dashed #bfc9d4;
}
.widget-account-invoice-one .invoice-box [class*=info-detail-]:not(.info-sub) {
  display: flex;
  justify-content: space-between;
}
.widget-account-invoice-one .invoice-box [class*=info-detail-]:not(.info-sub) p {
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 14px;
}
.widget-account-invoice-one .invoice-box [class*=info-detail-].info-sub .info-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
}
.widget-account-invoice-one .invoice-box [class*=info-detail-].info-sub .info-detail p {
  margin-bottom: 0;
}
.widget-account-invoice-one .invoice-box [class*=info-detail-].info-sub .info-detail-sub {
  margin-left: 9px;
}
.widget-account-invoice-one .invoice-box [class*=info-detail-].info-sub .info-detail-sub p {
  color: #888ea8;
  margin-bottom: 2px;
  font-weight: 600;
}
.widget-account-invoice-one .invoice-box .inv-action {
  text-align: center;
  display: flex;
  justify-content: space-around;
}
.widget-account-invoice-one .invoice-box .inv-action a {
  transform: none;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    =====================
        Account Info
    =====================
*/
.widget.widget-wallet-one .wallet-title {
  letter-spacing: 0px;
  font-size: 18px;
  display: block;
  color: #0e1726;
  font-weight: 600;
  margin-bottom: 0;
}
.widget.widget-wallet-one .total-amount {
  font-size: 38px;
  color: #191e3a;
  font-weight: 600;
}
.widget.widget-wallet-one .wallet-text {
  color: #506690;
  letter-spacing: 1px;
  font-weight: 700;
}
.widget.widget-wallet-one .wallet-text:hover {
  color: #4361ee;
}
.widget.widget-wallet-one .wallet-text svg {
  width: 16px;
  height: 16px;
}
.widget.widget-wallet-one .wallet-action {
  padding: 4px 0px;
  border-radius: 10px;
  max-width: 350px;
  margin: 0 auto;
}
.widget.widget-wallet-one .list-group .list-group-item {
  border: none;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.widget.widget-wallet-one .list-group.list-group-media .list-group-item .media .media-body h6 {
  color: #0e1726;
  font-weight: 500;
}
.widget.widget-wallet-one .list-group .list-group-item .amount {
  position: absolute;
  top: 21px;
  right: 0;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    =====================
        Recent Orders
    =====================
*/
.widget-table-two {
  position: relative;
}
.widget-table-two h5 {
  margin-bottom: 20px;
}
.widget-table-two .widget-content {
  background: transparent;
}
.widget-table-two .table {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-bottom: 0;
  background: transparent;
}
.widget-table-two .table > thead > tr > th {
  text-transform: initial;
  font-weight: 600;
  border-top: none;
  background: rgba(186, 231, 255, 0.34);
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  padding: 10px 0 10px 15px;
}
.widget-table-two .table > thead > tr > th:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.widget-table-two .table > thead > tr > th:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}
.widget-table-two .table > thead > tr > th .th-content {
  color: #515365;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
}
.widget-table-two .table > thead > tr > th:first-child .th-content {
  margin-left: 10px;
}
.widget-table-two .table > thead > tr > th:last-child .th-content {
  margin-right: 10px;
}
.widget-table-two .table > thead > tr > th:nth-last-child(2) .th-content {
  text-align: center;
  padding: 0 15px 0 0;
}
.widget-table-two .table > tbody > tr > td {
  border-top: none;
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.widget-table-two .table > tbody > tr > td .td-content {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  color: #515365;
}
.widget-table-two .table > tbody > tr:hover > td .td-content {
  color: #888ea8;
}
.widget-table-two .table > tbody > tr > td:first-child {
  border-top-left-radius: 6px;
  padding: 10px 0 10px 15px;
  border-bottom-left-radius: 6px;
}
.widget-table-two .table > tbody > tr > td:last-child {
  border-top-right-radius: 6px;
  padding: 15.5px 0 15.5px 15px;
  border-bottom-right-radius: 6px;
}
.widget-table-two .table .td-content.customer-name {
  color: #515365;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 13px;
  display: flex;
}
.widget-table-two .table .td-content.product-brand {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.26);
  padding: 10px 0 10px 15px;
}
.widget-table-two .table .td-content.product-invoice {
  padding: 10px 0 10px 15px;
}
.widget-table-two .table .td-content.pricing {
  width: 50%;
  margin: 0 auto;
}
.widget-table-two .table .td-content img {
  width: 35px;
  height: 34px;
  border-radius: 6px;
  margin-right: 10px;
  padding: 2px;
  align-self: center;
}
.widget-table-two .table .td-content.customer-name span {
  align-self: center;
}
.widget-table-two .table tr > td:nth-last-child(2) .td-content {
  text-align: center;
}
.widget-table-two .table .td-content .badge {
  border: none;
  font-weight: 500;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*
    ===========================
        Top Selling Product
    ===========================
*/
.widget-table-three {
  position: relative;
}
.widget-table-three h5 {
  margin-bottom: 20px;
}
.widget-table-three .widget-content {
  background: transparent;
}
.widget-table-three .table {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-bottom: 0;
  background-color: transparent;
}
.widget-table-three .table > thead > tr > th {
  text-transform: initial;
  font-weight: 600;
  border-top: none;
  background: rgba(186, 231, 255, 0.34);
  border-right: none;
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  padding: 10px 0 10px 15px;
}
.widget-table-three .table > thead > tr > th:first-child .th-content {
  margin-left: 10px;
}
.widget-table-three .table > thead > tr > th:last-child .th-content {
  padding: 0 15px 0 0;
  width: 84%;
  margin: 0 auto;
}
.widget-table-three .table > thead > tr > th:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.widget-table-three .table > thead > tr > th:last-child {
  border-bottom-right-radius: 6px;
  padding-left: 0;
  border-top-right-radius: 6px;
}
.widget-table-three .table > thead > tr > th .th-content {
  color: #515365;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}
.widget-table-three .table > tbody > tr {
  background: transparent;
}
.widget-table-three .table > tbody > tr > td {
  border-top: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.widget-table-three .table > tbody > tr > td .td-content {
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 1px;
  color: #515365;
}
.widget-table-three .table > tbody > tr:hover > td .td-content {
  color: #888ea8;
}
.widget-table-three .table > tbody > tr > td:first-child {
  border-top-left-radius: 6px;
  padding: 12px 0px 12px 15px;
  border-bottom-left-radius: 6px;
}
.widget-table-three .table > tbody > tr > td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.widget-table-three .table > tbody > tr > td:last-child .td-content {
  padding: 0 15px 0 0;
  width: 50%;
  margin: 0 auto;
}
.widget-table-three .table tr > td:nth-last-child(2) .td-content {
  padding: 0 0 0 0;
  width: 50%;
  margin: 0 auto;
}
.widget-table-three .table .td-content .discount-pricing {
  padding: 10px 0 10px 15px;
}
.widget-table-three .table .td-content.product-name {
  color: #515365;
  letter-spacing: 1px;
  display: flex;
}
.widget-table-three .table .td-content.product-name .prd-name {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 13px;
}
.widget-table-three .table tr:hover .td-content.product-name .prd-name {
  color: #888ea8;
}
.widget-table-three .table .td-content.product-name .prd-category {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.26);
}
.widget-table-three .table .td-content img {
  width: 42px;
  height: 42px;
  border-radius: 6px;
  margin-right: 10px;
  padding: 2px;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.18);
  align-self: center;
}
.widget-table-three .table .td-content .pricing {
  padding: 10px 0 10px 15px;
}
.widget-table-three .table .td-content .tag {
  background: transparent;
  transform: none;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 2px 5px;
  border-radius: 6px;
}
.widget-table-three .table .td-content .tag-primary {
  color: #4361ee;
  border: 1px dashed #4361ee;
  background: #eceffe;
}
.widget-table-three .table .td-content .tag-success {
  color: #009688;
  border: 1px dashed #009688;
  background: #ddf5f0;
}
.widget-table-three .table .td-content .tag-danger {
  color: #e7515a;
  border: 1px dashed #e7515a;
  background: #fbeced;
}
.widget-table-three .table .td-content a {
  position: relative;
  padding: 0;
  font-size: 13px;
  background: transparent;
  transform: none;
  letter-spacing: 1px;
}
.widget-table-three .table .td-content a svg.feather-chevrons-right {
  width: 15px;
  height: 15px;
  position: absolute;
  left: -20px;
  top: 1px;
}

/*
    ===========================
    /|\                     /|\
    /|\                     /|\
    /|\    Sales Section    /|\
    /|\                     /|\
    /|\                     /|\
    ===========================
*/
