/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.filepond {
  margin: 0 auto;
}

.profile-image .filepond {
  width: 120px;
  height: 120px !important;
}

.multiple-file-upload .filepond {
  width: 100%;
}

.filepond--drop-label {
  cursor: pointer;
  font-size: 12px;
}
.filepond--drop-label label {
  cursor: pointer;
  font-size: 12px;
}

.filepond .no-image-placeholder {
  display: inline-block;
  margin-bottom: 5px;
}

.filepond--panel {
  background-color: #1b2e4b !important;
}
.filepond--panel[data-scalable=true] {
  background-color: #1b2e4b !important;
}

.filepond--root .filepond--drop-label, .filepond--drip, .filepond--panel-center, .filepond--panel-top, .filepond--panel-bottom {
  background-color: #eceffe;
  border-radius: 9px;
}

[data-filepond-item-state*=error] .filepond--item-panel, [data-filepond-item-state*=invalid] .filepond--item-panel {
  background-color: #e0e6ed;
}

.filepond--file {
  background-color: #515365 !important;
  color: #fff;
}

.filepond--file-action-button {
  background-color: #e0e6ed !important;
  color: #000;
}

.filepond--file-info {
  background-color: transparent !important;
  color: #fff;
}
.filepond--file-info .filepond--file-info-main {
  background-color: transparent !important;
}

.filepond--file .filepond--file-status {
  background-color: #515365 !important;
}

[data-filepond-item-state=processing-complete] .filepond--item-panel {
  background-color: #369763 !important;
  background-color: #369763 !important;
  background-color: #369763 !important;
}

.filepond--file-action-button.filepond--file-action-button svg {
  background: #e0e6ed;
  border-radius: 60px;
  color: #3b3f5c;
}
.filepond--file-action-button:focus, .filepond--file-action-button:hover {
  box-shadow: none;
}

.filepond .no-image-placeholder svg {
  height: 34px;
  width: 34px;
  stroke-width: 1.2;
  color: #000;
  fill: rgba(0, 0, 0, 0.1215686275);
}
.filepond .drag-para {
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
  margin-top: 9px;
}

.filepond--root .filepond--credits {
  display: none;
}
