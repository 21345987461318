/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
/*----------Theme checkbox---------*/
/*
  Filtered List Search
*/
.filtered-list-search form > div {
  position: relative;
  width: 80%;
}

.react-table .rdt_TableHead .rdt_TableHeadRow  {
  
  background-color: #ebedf2 !important;
  color: #515365;
  font: 14px Nunito;
  padding: 0px; 
  margin: 0px;
}

.filtered-list-search form > div svg {
  position: absolute;
  right: 11px;
  color: #eaeaec;
  height: 36px;
  width: 19px;
  top: 5px;
}
.filtered-list-search form input {
  background: #fff;
  border: 1px solid #e0e6ed;
  width: 100% !important;
}
.filtered-list-search form input:focus {
  border-color: #d3d3d3;
}
.filtered-list-search form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #888ea8;
}
.filtered-list-search form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #888ea8;
}
.filtered-list-search form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #888ea8;
}
.filtered-list-search form input:-moz-placeholder {
  /* Firefox 18- */
  color: #888ea8;
}

.searchable-container .switch {
  text-align: right;
}
.searchable-container .switch .view-grid, .searchable-container .switch .view-list {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  color: #888ea8;
  border: 1px solid #e0e6ed;
  width: 43px;
  height: 41px;
}
.searchable-container #btn-add-contact {
  padding: 9px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 35px;
  border: 1px solid #e0e6ed;
  width: 43px;
  height: 41px;
  color: #4361ee;
  fill: none;
}
.searchable-container #btn-add-contact:hover {
  color: #888ea8;
}
.searchable-container .add-contact-box .add-contact-content .contact-name .validation-text, .searchable-container .add-contact-box .add-contact-content .contact-email .validation-text, .searchable-container .add-contact-box .add-contact-content .contact-occupation .validation-text, .searchable-container .add-contact-box .add-contact-content .contact-phone .validation-text, .searchable-container .add-contact-box .add-contact-content .contact-location .validation-text {
  display: none;
  color: #e7515a;
  font-weight: 600;
  text-align: left;
  margin-top: 6px;
  font-size: 12px;
  letter-spacing: 1px;
}
.searchable-container .add-contact-box .add-contact-content .contact-name svg, .searchable-container .add-contact-box .add-contact-content .contact-email svg, .searchable-container .add-contact-box .add-contact-content .contact-occupation svg, .searchable-container .add-contact-box .add-contact-content .contact-phone svg, .searchable-container .add-contact-box .add-contact-content .contact-location svg {
  align-self: center;
  font-size: 19px;
  margin-right: 14px;
  color: #2196f3;
  font-weight: 600;
}
.searchable-container .add-contact-box .add-contact-content .contact-name #c-name::-webkit-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-email #c-email::-webkit-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-occupation #c-occupation::-webkit-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-phone #c-phone::-webkit-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-location #c-location::-webkit-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-name #c-name::-ms-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-email #c-email::-ms-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-occupation #c-occupation::-ms-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-phone #c-phone::-ms-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-location #c-location::-ms-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-name #c-name::-moz-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-email #c-email::-moz-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-occupation #c-occupation::-moz-input-placeholder, .searchable-container .add-contact-box .add-contact-content .contact-phone #c-phone::-moz-input-placeholder {
  color: #eaeaec;
  font-weight: 600;
}
.searchable-container .add-contact-box .add-contact-content .contact-location #c-location {
  resize: none;
}
.searchable-container .add-contact-box .add-contact-content .contact-location #c-location::-moz-input-placeholder {
  color: #eaeaec;
  font-weight: 600;
}
.searchable-container .switch .view-grid:hover, .searchable-container .switch .view-list:hover, .searchable-container .switch .active-view {
  color: #4361ee;
  fill: none;
}
.searchable-container .searchable-items.list .items.items-header-section h4 {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 39px;
  color: #3b3f5c;
}
.searchable-container .searchable-items.list .items.items-header-section .n-chk {
  display: inline-block;
}
.searchable-container .searchable-items.list .items .item-content {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 0.625rem;
  position: relative;
  display: inline-flex;
  min-width: 0;
  word-wrap: break-word;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 13px 18px;
  width: 100%;
  min-width: 767px;
  border: 1px solid #e0e6ed;
}
.searchable-container .searchable-items.list .items .item-content:hover {
  background: #fafafa;
}
.searchable-container .searchable-items.list .items .item-content:hover .action-btn .edit {
  color: #00ab55;
  fill: #fff;
}
.searchable-container .searchable-items.list .items .item-content:hover .action-btn .delete {
  color: #e7515a;
  fill: #fff;
}
.searchable-container .searchable-items.list .items .item-content:hover .user-meta-info .user-name, .searchable-container .searchable-items.list .items .item-content:hover .user-email p, .searchable-container .searchable-items.list .items .item-content:hover .user-location p, .searchable-container .searchable-items.list .items .item-content:hover .user-phone p {
  color: #4361ee;
}
.searchable-container .searchable-items.list .items .user-profile {
  display: flex;
}
.searchable-container .searchable-items.list .items .user-profile img {
  width: 43px;
  height: 43px;
  border-radius: 8px;
  margin-right: 11px;
  margin-left: 18px;
}
.searchable-container .searchable-items.list .items .user-meta-info .user-name {
  margin-bottom: 0;
  color: #3b3f5c;
  font-weight: 600;
  font-size: 15px;
}
.searchable-container .searchable-items.list .items .user-meta-info .user-work {
  margin-bottom: 0;
  color: #506690;
  font-weight: 500;
  font-size: 13px;
}
.searchable-container .searchable-items.list .items .user-email p {
  margin-bottom: 0;
  color: #506690;
  font-weight: 600;
}
.searchable-container .searchable-items.list .items .user-email .info-title {
  display: none;
}
.searchable-container .searchable-items.list .items .user-location p {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}
.searchable-container .searchable-items.list .items .user-location .info-title {
  display: none;
}
.searchable-container .searchable-items.list .items .user-phone p {
  margin-bottom: 0;
  color: #506690;
  font-weight: 600;
  font-size: 13px;
}
.searchable-container .searchable-items.list .items .user-phone .info-title {
  display: none;
}
.searchable-container .searchable-items.list .items .action-btn {
  font-weight: 600;
  color: #eaeaec;
}
.searchable-container .searchable-items.list .items .action-btn .delete-multiple {
  margin-right: 5px;
  cursor: pointer;
  color: #e7515a;
  width: 20px;
  fill: none;
}
.searchable-container .searchable-items.list .items .action-btn .delete-multiple:hover {
  color: #e7515a;
}
.searchable-container .searchable-items.list .items .action-btn .edit, .searchable-container .searchable-items.list .items .action-btn .delete {
  margin-right: 5px;
  cursor: pointer;
  color: #888ea8;
  width: 20px;
  fill: rgba(136, 142, 168, 0.09);
}

.searchable-items.grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.searchable-container .searchable-items.grid .items {
  margin-bottom: 30px;
  border-radius: 6px;
  width: 100%;
  color: #0e1726;
  width: 33%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.searchable-container .searchable-items.grid .items .item-content {
  background-color: #fff;
  border: 1px solid #e0e6ed;
  padding: 13px 18px;
  border-radius: 6px;
}
.searchable-container .searchable-items.grid .items .item-content:hover {
  background: #fafafa;
}
.searchable-container .searchable-items.grid .items .item-content:hover .action-btn .edit {
  color: #00ab55;
  fill: #fff;
}
.searchable-container .searchable-items.grid .items .item-content:hover .action-btn .delete {
  color: #e7515a;
  fill: #fff;
}
.searchable-container .searchable-items.grid .items.items-header-section {
  display: none;
}
.searchable-container .searchable-items.grid .items .user-profile {
  text-align: center;
  margin-top: 20px;
}
.searchable-container .searchable-items.grid .items .user-profile .n-chk {
  display: none;
}
.searchable-container .searchable-items.grid .items .user-profile img {
  border-radius: 12px;
}
.searchable-container .searchable-items.grid .items .user-meta-info {
  margin-top: 10px;
}
.searchable-container .searchable-items.grid .items .user-meta-info .user-name {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 0;
  color: #009688;
}
.searchable-container .searchable-items.grid .items .user-meta-info .user-work {
  font-weight: 700;
  font-size: 13px;
}
.searchable-container .searchable-items.grid .items .user-email {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.searchable-container .searchable-items.grid .items .user-email .info-title {
  font-size: 14px;
  font-weight: 600;
  color: #3b3f5c;
  margin-bottom: 11px;
}
.searchable-container .searchable-items.grid .items .user-email p {
  color: #888ea8;
  font-size: 13px;
  margin-bottom: 11px;
}
.searchable-container .searchable-items.grid .items .user-location {
  display: flex;
  justify-content: space-between;
}
.searchable-container .searchable-items.grid .items .user-location .info-title {
  font-size: 14px;
  font-weight: 600;
  color: #3b3f5c;
  margin-bottom: 11px;
  margin-right: 10px;
}
.searchable-container .searchable-items.grid .items .user-location p {
  color: #888ea8;
  font-size: 13px;
  margin-bottom: 11px;
}
.searchable-container .searchable-items.grid .items .user-phone {
  display: flex;
  justify-content: space-between;
}
.searchable-container .searchable-items.grid .items .user-phone .info-title {
  font-size: 14px;
  font-weight: 600;
  color: #3b3f5c;
  margin-bottom: 11px;
}
.searchable-container .searchable-items.grid .items .user-phone p {
  color: #888ea8;
  font-size: 13px;
  margin-bottom: 11px;
  margin-right: 0;
}
.searchable-container .searchable-items.grid .items .action-btn {
  font-weight: 600;
  color: #eaeaec;
  text-align: center;
  margin: 20px 0;
}
.searchable-container .searchable-items.grid .items .action-btn .edit, .searchable-container .searchable-items.grid .items .action-btn .delete {
  margin-right: 5px;
  cursor: pointer;
  color: #888ea8;
  width: 20px;
  fill: rgba(136, 142, 168, 0.09);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .new-control.new-checkbox .new-control-indicator {
    top: -13px;
    left: -8px;
  }
}
@media (max-width: 1199px) {
  .searchable-container .searchable-items.list {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .searchable-container .searchable-items.grid .items {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (max-width: 767px) {
  .searchable-container .searchable-items.list {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .searchable-container .searchable-items.list .items {
    min-width: 767px;
  }
  .searchable-container .searchable-items.grid .items {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 575px) {
  .searchable-container .searchable-items.grid .items {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .filtered-list-search form > div {
    width: 100%;
  }
}
