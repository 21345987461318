/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.widget-content-area, .dataTables_wrapper {
  padding: 0;
}

@media screen and (max-width:765px) {
  .options {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
}

.details_info {
  border: 2px solid black;
  padding: 10px;
  width: 100% !important;
}


.card_text p {
  margin: 0px 10px 10px 10px;
}


.selected {
  border-color: #80bdff; /* Color de borde cuando tiene el foco */
  outline: 0; /* Elimina el contorno predeterminado de algunos navegadores */
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

div.dataTables_wrapper button:hover {
  -webkit-transform: none;
  transform: none;
}

.inv-list-top-section {
  margin: 20px 21px 20px 21px;
}

div.dataTables_wrapper div.dataTables_length {
  align-self: center;
}
div.dataTables_wrapper div.dataTables_length label {
  margin-bottom: 0;
  margin-right: 15px;
}

.dataTables_wrapper .dataTables_length select.form-control {
  margin: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  align-self: center;
}
div.dataTables_wrapper div.dataTables_filter svg {
  top: 10px;
}
div.dataTables_wrapper div.dataTables_filter label {
  margin: 0;
  margin-right: 15px;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin: 0;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: hidden;
}

table.dataTable {
  margin: 0 !important;
}

.table > thead {
  border-top: none;
  border-bottom: none;
}
.table > thead > tr > th {
  text-transform: initial;
  font-weight: 600;
  border-top: none;
  border-right: none;
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  padding: 10px 21px 10px 21px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  white-space: nowrap;
}
.table > thead > tr > th:first-child:before, .table > thead > tr > th:first-child:after {
  display: none;
}
.table > thead > tr > th:last-child:before, .table > thead > tr > th:last-child:after {
  display: none;
}
.table > tbody:before {
  display: none;
}
.table > tbody > tr > td {
  padding: 0;
  padding: 10px 21px 10px 21px;
  letter-spacing: normal;
  white-space: nowrap;
}
.table > tbody > tr > td:first-child {
  border-top-left-radius: 8px;
}
.table > tbody > tr > td .inv-number {
  color: #4361ee;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
}
.table > tbody > tr > td .user-name {
  color: #3b3f5c;
  font-size: 14px;
  letter-spacing: 0.14px;
  margin-bottom: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}
.table > tbody > tr > td .inv-email {
  color: #506690;
  font-size: 14px;
  letter-spacing: 0.14px;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}
.table > tbody > tr > td .inv-email svg {
  width: 17px;
  height: 17px;
  vertical-align: text-bottom;
  color: #805dca;
  stroke-width: 1.5;
}
.table > tbody > tr > td .inv-date svg {
  width: 17px;
  height: 17px;
  vertical-align: text-top;
  color: #2196f3;
  stroke-width: 1.5;
}
.table > tbody > tr > td .dropdown .dropdown-toggle svg {
  stroke-width: 1px;
}
.table > tbody > tr > td .dropdown.show .dropdown-toggle svg {
  stroke-width: 1px;
  color: #7367f0;
}
.table > tbody > tr > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
  padding: 9px !important;
}
.table > tbody > tr:last-child > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu, .table > tbody > tr:nth-last-child(2) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
  top: -94px !important;
}
.table > tbody > tr:last-child > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show, .table > tbody > tr:nth-last-child(2) > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
  top: -90px !important;
}
.table > tbody > tr > td .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item svg {
  width: 16px;
  height: 16px;
  margin-right: 7px;
  vertical-align: text-top;
}

/* 
    Inv List Bottom Section
*/
.inv-list-bottom-section {
  padding: 15px;
}
