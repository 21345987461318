/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.widget-content-area {
  border: 1px solid #e0e6ed;
}
.widget-content-area h1, .widget-content-area h2, .widget-content-area h3, .widget-content-area h4, .widget-content-area h5, .widget-content-area h6 {
  color: #3b3f5c;
}

.swiper-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  bottom: -37px;
  left: 0;
  right: 0;
}
.swiper-pagination .swiper-pagination-bullet {
  margin-right: 5px;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #6c4dff;
}

/*
    ===============================
        Product Details Content
    ===============================
*/
#main-slider .splide__track {
  border-radius: 12px;
}

.splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: none;
}

#main-slider .splide__list .glightbox {
  display: block;
  height: 100%;
}
#main-slider .splide__list .glightbox img {
  display: block;
  width: 100%;
  border-radius: 12px;
  height: 100%;
}

#thumbnail-slider {
  margin-top: 30px;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}
#thumbnail-slider .splide__track {
  border-radius: 8px;
}
#thumbnail-slider .splide__slide {
  border-radius: 8px;
  filter: blur(1px);
  transition: filter 0.5s;
}
#thumbnail-slider .splide__slide.is-active {
  filter: blur(0);
}
#thumbnail-slider .splide__arrow--prev {
  left: -13px;
}
#thumbnail-slider .splide__arrow--next {
  right: -13px;
}
#thumbnail-slider .splide__arrow {
  opacity: 1;
  background: #e0e6ed;
}

/*
    ===============================
        Product Details Content
    ===============================
*/
.product-details-content hr {
  border-top: 1px solid #bfc9d4;
}
.product-details-content .bootstrap-touchspin-injected input {
  border: 1px solid #e0e6ed;
}
.product-details-content .bootstrap-touchspin-injected .input-group-prepend button {
  background-color: #eaeaec;
  border-color: #e0e6ed;
  box-shadow: none;
  color: #000 !important;
}
.product-details-content .bootstrap-touchspin-injected .input-group-prepend button:hover {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  color: #000 !important;
}
.product-details-content .bootstrap-touchspin-injected .input-group-append button {
  background-color: #eaeaec;
  border-color: #e0e6ed;
  box-shadow: none;
  color: #000 !important;
}
.product-details-content .bootstrap-touchspin-injected .input-group-append button:hover {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  color: #000 !important;
}
.product-details-content .product-helpers {
  font-size: 13px;
  font-weight: 600;
  color: #3b3f5c;
}
.product-details-content .product-helpers:hover {
  text-decoration: underline;
}
.product-details-content .product-title {
  font-weight: 700;
}
.product-details-content .review {
  display: inline-block;
  cursor: pointer;
}
.product-details-content .review svg {
  fill: #e2a03f;
  color: #e2a03f;
  stroke-width: 0;
  width: 20px;
  height: 20px;
  vertical-align: sub;
}
.product-details-content .review .rating-score {
  font-weight: 700;
  color: #000;
}
.product-details-content .review .rating-count {
  color: #888ea8;
  font-weight: 600;
}
.product-details-content .pricing {
  font-size: 25px;
  font-weight: 700;
  color: #3b3f5c;
}
.product-details-content .pricing .regular-price {
  margin-right: 5px;
  color: #888ea8;
  font-size: 16px;
  text-decoration: line-through;
  vertical-align: middle;
  display: inline-block;
}
.product-details-content .pricing .discounted-price {
  vertical-align: middle;
  display: inline-block;
}
.product-details-content .color-swatch {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.product-details-content .color-swatch .form-check {
  margin-right: 0;
  margin-bottom: 0;
}
.product-details-content .color-swatch .form-check .form-check-input {
  border: none;
}
.product-details-content .color-swatch .form-check-input {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 8px;
}
.product-details-content .color-swatch .form-check-input:checked {
  border: none;
}
.product-details-content .color-swatch .form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.product-details-content .secure-info {
  padding: 12px 12px;
  background: #f1f2f3;
  border-radius: 14px;
  display: flex;
}
.product-details-content .secure-info svg {
  margin-right: 10px;
  color: #e2a03f;
  fill: rgba(226, 160, 63, 0.368627451);
}
.product-details-content .secure-info p {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
  align-self: center;
}
.product-details-content .size-selector, .product-details-content .quantity-selector {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.product-details-content .product-description {
  font-size: 15px;
  font-weight: 200;
  color: #bfc9d4;
}

/*
    =================================
        Production Descriptions
    =================================
*/
.production-descriptions {
  padding: 20px;
}
.production-descriptions .pro-des-content {
  max-width: 1040px;
  margin: 0 auto;
}
.production-descriptions .pro-des-content .accordion hr {
  border-top: 1px solid #bfc9d4;
}
.production-descriptions .pro-des-content .accordion .card {
  border: none;
  border-bottom: 1px solid #e0e6ed;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}
.production-descriptions .pro-des-content .accordion .card:first-child {
  border-top: 1px solid #e0e6ed;
}
.production-descriptions .pro-des-content .accordion .card .card-header section > div {
  padding: 13px 0;
  color: #3b3f5c;
  font-size: 15px;
  font-weight: 600;
}
.production-descriptions .pro-des-content .accordion .card .card-header section > div .accordion-icon svg {
  width: 26px;
  height: 26px;
  color: #4361ee;
  fill: none;
  stroke-width: 1.5;
  margin-right: 0;
}
.production-descriptions .pro-des-content .accordion .card .card-header section > div:not(.collapsed) {
  border-bottom: 1px solid #e0e6ed;
  color: #4361ee;
}
.production-descriptions .pro-des-content .accordion .card .card-header section > div.collapsed .accordion-icon svg {
  color: #3b3f5c;
}
.production-descriptions .pro-des-content .accordion .card .card-body {
  padding: 24px 0;
}
.production-descriptions .pro-des-content .accordion .card .card-body p {
  font-size: 14px;
  color: #3b3f5c;
}
.production-descriptions .pro-des-content .accordion .card .card-body .media img {
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.production-descriptions .pro-des-content .accordion .card .card-body .media .media-body {
  position: relative;
}
.production-descriptions .pro-des-content .accordion .card .card-body .media .media-body h4 {
  font-size: 16px;
  font-weight: 600;
}
.production-descriptions .pro-des-content .accordion .card .card-body .media .media-body .stars svg {
  fill: #e2a03f;
  color: #e2a03f;
  stroke-width: 0;
  width: 17px;
  height: 17px;
}
.production-descriptions .pro-des-content .accordion .card .card-body .media .media-body .stars svg.empty-star {
  stroke-width: 1px;
  fill: #fcf5e9;
  opacity: 0.5;
}
.production-descriptions .pro-des-content .accordion .card .card-body .media .media-body .meta-tags {
  position: absolute;
  top: 0;
  right: 0;
  color: #506690;
}
.production-descriptions .nav-link {
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 700;
}
.production-descriptions .nav-link.active {
  border-radius: 8px;
}
.production-descriptions .tab-content p {
  color: #3b3f5c;
}
.production-descriptions .product-reviews {
  background: #fafafa;
  padding: 32px 50px;
  border-radius: 26px;
  border: 1px solid #e0e6ed;
}
.production-descriptions .product-reviews .reviews h1 {
  font-weight: 600;
  font-size: 40px;
}
.production-descriptions .product-reviews .reviews .stars svg {
  fill: #e2a03f;
  color: #e2a03f;
  stroke-width: 0;
}
.production-descriptions .product-reviews .reviews .stars svg.empty-star {
  stroke-width: 1px;
  fill: #fcf5e9;
  opacity: 0.5;
}
.production-descriptions .product-reviews .reviews span {
  font-size: 15px;
  font-weight: 200;
  color: #3b3f5c;
  letter-spacing: 1px;
}
.production-descriptions .product-reviews .review-progress p {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
}
.production-descriptions .product-reviews .review-progress .progress {
  height: 8px;
  border-radius: 10px;
  margin-bottom: 0;
  background: #e0e6ed;
}
.production-descriptions .product-reviews .review-progress .progress-bar {
  border-radius: 0;
}
.production-descriptions .product-reviews .media img {
  border-radius: 15px;
  border: none;
}
.production-descriptions .product-reviews .media .media-body .media-heading {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
.production-descriptions .product-reviews .media .stars svg {
  fill: #e2a03f;
  color: #e2a03f;
  stroke-width: 0;
  width: 16px;
  height: 16px;
}

@media (max-width: 1199px) {
  .production-descriptions {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .production-descriptions .product-reviews {
    padding: 32px 32px;
  }
  .production-descriptions .media {
    display: block;
  }
  .production-descriptions .media img {
    margin-bottom: 15px;
  }
  #main-slider .splide__slide {
    width: 320px !important;
    height: 320px !important;
    margin: 0 auto;
  }
}
