@import '../../base/base';

.tagify {
  background: #fff;
  border: 1px solid #e0e6ed;
  border-radius: 6px;

  &:hover {
    border: 1px solid #bfc9d4;
  }

  &.tagify--focus {
    border: 1px solid $dark;
  }
}

.tagify__tag > div {
  background: #e0e6ed;
  color: $dark;
  padding: 9px 14px!important;
  border-radius: 11px;
}

.tagify__input {
  padding: 9px 20px!important;
}

.tagify__tag {
  > div::before {
    box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) #e0e6ed inset;
    background: #e0e6ed;
  }

  &.tagify--notAllowed:not(.tagify__tag--editable) div::before {
    box-shadow: none !important;
  }
}

.tagify__tag__removeBtn {
  color: #fff;
  background: #181e3a;
  font-size: 11px;

  &:after {
    margin-left: 0.5px;
  }

  &:hover {
    background: #0e1726;
  }
}

.tagify__tag {
  &:focus div::before, &:hover:not([readonly]) div::before {
    box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) #e0e6ed inset;
  }
}

.tagify__tag__removeBtn:hover + div::before {
  box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) #e0e6ed inset !important;
}

.tagify__tag__avatar-wrap img {
  width: 16px;
  height: 16px;
  margin-right: 9px;
  border-radius: 6px;
}

.tagify__tag:hover .tagify__tag__avatar-wrap {
  transform: scale(1.6) translateX(-10%);
}

.tagify__input::before {
  color: #bfc9d4;
  margin-top: 3px;
}

.tagify__dropdown__wrapper {
  background: #fff;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0.1px 0px rgb(0 0 0 / 0%), 0 0.2px 0px rgb(0 0 0 / 0%), 0 0.4px 0px rgb(0 0 0 / 0%), 0 0.6px 0px rgb(0 0 0 / 0%), 0 0.9px 0px rgb(0 0 0 / 1%), 0 1.2px 0px rgb(0 0 0 / 1%), 0 1.8px 0px rgb(0 0 0 / 1%), 0 2.6px 0px rgb(0 0 0 / 1%), 0 3.9px 0px rgb(0 0 0 / 1%), 0 7px 0px rgb(0 0 0 / 1%);
}

.tagify__input:focus:empty::before {
  color: #bfc9d4;
}

/* Suggestions items */

.tagify__dropdown.users-list {
  .tagify__dropdown__item {
    padding: .5em .7em;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 1em;
    grid-template-areas: "avatar name" "avatar email";

    &:hover .tagify__dropdown__item__avatar-wrap {
      transform: scale(1.2);
    }
  }

  .tagify__dropdown__item__avatar-wrap {
    grid-area: avatar;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    background: #EEE;
    transition: .1s ease-out;
  }

  img {
    width: 100%;
    vertical-align: top;
  }

  strong {
    grid-area: name;
    width: 100%;
    align-self: center;
    color: $dark;
  }

  span {
    grid-area: email;
    width: 100%;
    font-size: .9em;
    color: #888ea8;
  }

  .addAll {
    border-bottom: 1px solid #e0e6ed;
    gap: 0;
  }
}

.tagify__dropdown__item--active {
  background: #ebedf2;
  color: $dark;
}